/** @jsx jsx */

import { useMemo } from 'react';

import { jsx } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';

/**
 * NOTE: a combination of approaches to resolve layout issues:
 * 1. flex support
 * 2. grid support
 */
const directionMap = {
  row: ['minHeight', 'width'],
  column: ['minWidth', 'height'],
};

type DividerProps = {
  /** Does not accept children */
  children?: never;
  /** Does not accept css */
  css?: never;
  /** Display the divider vertically */
  vertical?: boolean;
};

export const Divider = ({ vertical }: DividerProps) => {
  const { palette } = useTheme();
  const orientation = vertical ? 'vertical' : 'horizontal';

  const styles = useMemo(() => {
    const direction = vertical ? 'row' : 'column';
    const [mainAxis, crossAxis] = directionMap[direction];

    return {
      alignSelf: 'stretch', // 1.
      backgroundColor: palette.border.standard,
      flexShrink: 0,
      [crossAxis]: 1,
      [mainAxis]: '100%', // 2.
    };
  }, [vertical, palette.border.standard]);

  return <div role="separator" aria-orientation={orientation} css={styles} />;
};
