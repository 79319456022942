export type DataAttributeMap = Record<
  string,
  string | number | boolean | undefined
>;

export const buildDataAttributes = (
  data: DataAttributeMap = {},
  prefix?: string
): DataAttributeMap => {
  const keys = Object.keys(data);
  const dataAttributes: DataAttributeMap = {};
  const prefixString = prefix ? `data-${prefix}-` : 'data-';

  for (const key of keys) {
    dataAttributes[`${prefixString}${key}`] = data[key];
  }

  return dataAttributes;
};
