/** @jsx jsx */

import React from 'react';

import { jsx } from '@reckon-web/core';

import { makeId } from './useId';

// This component provides a <Provider> component that must be included at the root of the app
// and a useImperativeRenderAtRoot hook that uses context to allow you to imperatively render
// any react node into the root of the application.
//
// Use cases for this include:
// renderering react content offscreen to generate content from (for example a pdf, or a canvas)
// rendering nodes offscreen for measuring width/height
// providing an imperative api for rendering modals or drawers.
//
// How to use:
// const renderIntoRoot = useImperativeRenderAtRoot();
// // render the element into the root and get a remove function
// const removeFromRoot = await renderIntoRoot();
// // once finished with element
// removeFromRoot();
//
// Note that at this stage this is only used to render into the root node

type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;

type ImperativeRenderContextState = {
  renderIntoRoot: (component: React.ReactNode) => Promise<() => void>;
};

const ImperativeRenderAtRootContext = React.createContext<
  ImperativeRenderContextState
>({
  renderIntoRoot: () => {
    throw new Error(
      'Attempted to call useImperativeRenderAtRoot outside of context. Make sure your component is inside ImperativeRenderAtRootProvider.'
    );
  },
});

export const ImperativeRenderAtRootProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [componentsToRender, setComponentsToRender] = React.useState<{
    [id: string]: React.ReactNode;
  }>({});
  const unrenderedComponents = React.useRef<{
    [id: string]: PromiseResolve<() => void>;
  }>({});

  // The function that gets passed to context consumers
  // In addition to updating the dom with the new components to render
  // this function also creates a new resolvable promise that it adds to a stable ref
  // which gets resolved once the new component has actually rendered
  const renderIntoRoot = React.useCallback((component: React.ReactNode) => {
    const newId = makeId();
    let resolveRender: PromiseResolve<() => void> = () => {};

    const awaitedRender = new Promise<() => void>((resolve) => {
      resolveRender = resolve;
    });

    setComponentsToRender((prevComponentsToRender) => ({
      ...prevComponentsToRender,
      [newId]: React.isValidElement(component)
        ? React.cloneElement(component, { key: newId })
        : component,
    }));

    unrenderedComponents.current[newId] = resolveRender;

    return awaitedRender;
  }, []);

  // If any components to render have changed, we check for any unresolved
  // functions, and then resolve them with a function that can remove the dom node from the dom
  React.useEffect(() => {
    Object.entries(unrenderedComponents.current).forEach(
      ([id, resolveRenderFunc]) => {
        if (componentsToRender[id]) {
          delete unrenderedComponents.current.id;

          resolveRenderFunc(() => {
            // this removes a dom node from the dom
            setComponentsToRender((prevComponentsToRender) => {
              const {
                [id]: componentToRemove,
                ...otherComponents
              } = prevComponentsToRender;
              return otherComponents;
            });
          });
        }
      }
    );
  }, [componentsToRender]);

  return (
    <>
      {Object.values(componentsToRender)}
      <ImperativeRenderAtRootContext.Provider value={{ renderIntoRoot }}>
        {children}
      </ImperativeRenderAtRootContext.Provider>
    </>
  );
};

export const useImperativeRenderAtRoot = () => {
  const imperativeRenderAtRoot = React.useContext(
    ImperativeRenderAtRootContext
  );
  return imperativeRenderAtRoot.renderIntoRoot;
};
