/** @jsx jsx */

import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { forwardRefWithAs } from '@reckon-web/utils';

type DescriptionProps = {
  /** The ID of the element. */
  id?: string;
};

export const FieldDescription = forwardRefWithAs<'div', DescriptionProps>(
  (props, ref) => {
    return <Text color="muted" size="small" ref={ref} {...props} />;
  }
);
