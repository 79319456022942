import chroma from 'chroma-js';

import { ReckonTheme } from './theme';
import { DeepPartial } from './utils';

const mix = (a: string, b: string) => (n: number) =>
  chroma.mix(a, b, n, 'rgb').hex();
const rgba = (clr: string, val: number) =>
  `rgba(${chroma(clr).alpha(val).rgba()})`;
const brighten = (clr: string, val: number) => chroma(clr).brighten(val).hex();
const darken = (clr: string, val: number) => chroma(clr).darken(val).hex();

// FIXME: the palette is caught between system tokens and component tokens. Pick a lane.

export const defaultPaletteResolver = (colors: ReckonTheme['colors']) => {
  const neutral = mix(colors.background, colors.text);

  // neutrals
  const background = colors.background;
  const backgroundMuted = neutral(0.03);
  const backgroundDim = neutral(0.06);
  const backgroundShade = neutral(0.09);

  const border = neutral(0.12);

  const text = colors.text;
  const textMuted = neutral(0.75);
  const textDim = neutral(0.5);

  const dialogBg = brighten(background, 0.4);

  return {
    // intentionally non-semantic colours used for avatars
    decorative: colors.decorative,
    variant: colors.variant,

    // Actions applies to buttons, and maybe other stuff?
    actions: {
      passive: textMuted,
      active: colors.primary,
      critical: colors.danger,
    },

    // Miscellaneous colours that don't fit elsewhere
    global: {
      accent: colors.accent,
      border: border,
      focusRing: rgba(colors.primary, 0.2),
      loading: colors.primary,
    },

    // Border colors
    border: {
      standard: border,
      muted: backgroundDim,

      // tonal
      accent: mix(colors.background, colors.accent)(0.24),
      active: mix(colors.background, colors.primary)(0.24),
      cautious: mix(colors.background, colors.warning)(0.24),
      critical: mix(colors.background, colors.danger)(0.24),
      informative: mix(colors.background, colors.info)(0.24),
      positive: mix(colors.background, colors.success)(0.24),
    },

    // Various background colours
    background: {
      base: background,
      muted: backgroundMuted,
      dim: backgroundDim,
      shade: backgroundShade,

      // special
      dialog: dialogBg,
      accent: mix(colors.background, colors.accent)(0.16),

      // selectable
      selectableHover: neutral(0.02),
      selectablePressed: neutral(0.04),
      selectableSelected: mix(colors.background, colors.primary)(0.04),
      selectableSelectedHover: mix(colors.background, colors.primary)(0.08),

      // tonal
      active: mix(colors.background, colors.primary)(0.16),
      cautious: mix(colors.background, colors.warning)(0.16),
      critical: mix(colors.background, colors.danger)(0.16),
      informative: mix(colors.background, colors.info)(0.16),
      positive: mix(colors.background, colors.success)(0.16),

      // tonalMuted
      activeMuted: mix(colors.background, colors.primary)(0.2),
      cautiousMuted: mix(colors.background, colors.warning)(0.2),
      criticalMuted: mix(colors.background, colors.danger)(0.2),
      informativeMuted: mix(colors.background, colors.info)(0.2),
      positiveMuted: mix(colors.background, colors.success)(0.2),
    },

    // Various text colours
    text: {
      base: text,
      muted: textMuted,
      dim: textDim,

      // links
      link: colors.primary,
      linkHover: brighten(colors.primary, 0.33),

      // tonal
      accent: colors.accent,
      active: colors.primary,
      cautious: colors.warning,
      critical: colors.danger,
      informative: colors.info,
      positive: colors.success,
    },

    // Inputs
    // ------------------------------
    formInput: {
      background: backgroundDim,
      backgroundHovered: backgroundDim,
      backgroundFocused: backgroundDim,
      backgroundDisabled: backgroundMuted,
      backgroundInvalid: mix(colors.background, colors.danger)(0.16),

      border: backgroundDim,
      borderHovered: border,
      borderFocused: colors.primary,
      borderDisabled: backgroundMuted,
      borderInvalid: mix(colors.background, colors.danger)(0.16),

      text: text,
      textDisabled: textMuted,
      textPlaceholder: textDim,
      textInvalid: colors.danger,
    },
    formControl: {
      background: background,
      backgroundDisabled: border,

      border: border,
      interaction: colors.primary,

      foregroundChecked: background,
      foregroundDisabled: textDim,
    },
    segmentedControl: {
      track: backgroundDim,
      divider: rgba(colors.text, 0.16),
      backgroundSelected: brighten(background, 0.8),
      text: textMuted,
      textFocused: text,
      textPressed: text,
      textSelected: text,
    },
    toggle: {
      track: border,
      trackChecked: colors.primary,
      trackDisabled: backgroundDim,
      trackDisabledChecked: textDim,
      handle: 'white',
    },

    // Lists
    // ------------------------------
    listItem: {
      backgroundFocused: neutral(0.08),
      backgroundPressed: neutral(0.12),
      backgroundSelected: neutral(0.12),
      divider: border,
      text: text,
      textFocused: text,
      textPressed: text,
      textSelected: text,
    },

    // Menus and overlays
    // ------------------------------
    menuItem: {
      backgroundFocused: mix(dialogBg, colors.text)(0.06),
      backgroundPressed: mix(dialogBg, colors.text)(0.12),
      backgroundSelected: mix(dialogBg, colors.primary)(0.06),
      divider: border,
      text: text,
      textDisabled: textDim,
      textFocused: text,
      textPressed: text,
      textSelected: text,
    },
    tooltip: {
      background: dialogBg,
      text: text,
    },

    // Buttons
    // ------------------------------
    button: {
      active: {
        background: colors.primary,
        backgroundFocused: brighten(colors.primary, 0.16),
        backgroundPressed: darken(colors.primary, 0.24),
      },
      critical: {
        background: colors.danger,
        backgroundFocused: brighten(colors.danger, 0.16),
        backgroundPressed: darken(colors.danger, 0.24),
      },
      passive: {
        background: textMuted,
        backgroundFocused: brighten(textMuted, 0.16),
        backgroundPressed: darken(textMuted, 0.24),
      },
    },
    actionButton: {
      background: background,
      backgroundFocused: neutral(0.06),
      backgroundPressed: neutral(0.12),
      backgroundSelected: neutral(0.12),
      border: neutral(0.12),
      borderFocused: neutral(0.16),
      borderPressed: neutral(0.24),
      borderSelected: neutral(0.24),
      text: textMuted,
      textFocused: text,
      textPressed: text,
      textSelected: text,
    },
  };
};

export type PaletteType = ReturnType<typeof defaultPaletteResolver>;
export type PartialPaletteType = DeepPartial<PaletteType>;
export type PaletteSignature = typeof defaultPaletteResolver;
export type PartialPaletteSignature = (
  colors: ReckonTheme['colors']
) => PartialPaletteType;
