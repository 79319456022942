/** @jsx jsx */

import { Children, Fragment, ReactElement, ReactNode } from 'react';

import { Flex, FlexProps } from '@reckon-web/flex';
import { jsx } from '@reckon-web/core';
import { Divider } from '@reckon-web/divider';
import { forwardRefWithAs } from '@reckon-web/utils';

type SupportedFlexProps = Omit<
  FlexProps,
  'direction' | 'alignItems' | 'justifyContent' | 'inline' | 'wrap'
>;

const alignHorizontalMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;

export type StackProps = {
  /** Horizontally align elements within the stack. */
  align?: keyof typeof alignHorizontalMap;
  /** The elements of the stack. Note that `Fragment` will be treated as a single element. */
  children: ReactNode;
  /** Place a divider between each element. */
  dividers?: boolean;
} & SupportedFlexProps;

export const Stack = forwardRefWithAs<'div', StackProps>(
  ({ align, children, dividers, ...props }, forwardedRef) => {
    const alignItems = align ? alignHorizontalMap[align] : 'stretch';
    const rootProps = {
      ref: forwardedRef,
      alignItems: alignItems,
      direction: 'column',
      ...props,
    } as const;

    // bail early w/o dividers to avoid unnecessary map
    if (!dividers) {
      return <Flex {...rootProps}>{children}</Flex>;
    }

    // map over children to insert dividers
    // remove falsy values before mapping, keeps the index in sync
    const childArray = Children.toArray(children) as ReactElement[];
    return (
      <Flex {...rootProps}>
        {childArray.map((child, idx) => (
          <Fragment key={child.key || idx}>
            {dividers && idx ? <Divider /> : null}
            {child}
          </Fragment>
        ))}
      </Flex>
    );
  }
);
