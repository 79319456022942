/** @jsx jsx */

import { FC } from 'react';

import { ResponsiveProp, jsx } from '@reckon-web/core';
import { PaletteType, useTheme } from '@reckon-web/theme';
import { useMediaQuery } from '@reckon-web/media-query';

// TODO: colors are actually tones now. this must be updated when folks stop working on application code

export type IconProps = {
  /** The color key for the SVG fill property. */
  color?: keyof PaletteType['text'];
  /** The size key for the icon. */
  size?: ResponsiveProp<keyof typeof sizeMap> | number;
  /** Does not support css. */
  css?: never;
};

// TODO: Move to theme?
const sizeMap = {
  small: 16,
  medium: 24,
  large: 32,
};

export const createIcon = (children: React.ReactNode, name: string) => {
  let Icon: FC<IconProps> = ({ size = 'medium', color: colorKey }) => {
    const { palette } = useTheme();
    const { mq, mapResponsiveProp } = useMediaQuery();
    const resolvedSize =
      typeof size === 'number' ? size : mapResponsiveProp(size, sizeMap);
    const color = colorKey ? palette.text[colorKey] : 'currentColor';

    return (
      <svg
        aria-hidden="true"
        focusable="false"
        css={mq({
          verticalAlign: 'text-bottom', // removes whitespace inside buttons
          fill: 'none',
          stroke: color,
          strokeLinejoin: 'round',
          strokeLinecap: 'round',
          strokeWidth: 2,
          height: resolvedSize,
          width: resolvedSize,
        })}
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {children}
      </svg>
    );
  };

  Icon.displayName = name;

  return Icon;
};
