/** @jsx jsx */

import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
  useCallback,
} from 'react';

import { jsx } from '@reckon-web/core';
import { useFieldContext } from '@reckon-web/field';
import { ChevronDownIcon } from '@reckon-web/icon/icons/ChevronDownIcon';
import { useInputStyles } from '@reckon-web/text-input';
import { useTheme } from '@reckon-web/theme';

type NativeSelectProps = InputHTMLAttributes<HTMLSelectElement>;
type ValidSelectProps = Pick<NativeSelectProps, 'onBlur' | 'onFocus'>;

type Option<Value> = Readonly<{
  disabled?: boolean;
  label: string;
  value: Value;
}>;
type Group<Option> = Readonly<{ options: Option[]; label: string }>;
type OptionsOrGroups<Value> = readonly (Option<Value> | Group<Option<Value>>)[];

export type SelectInputProps<Value> = {
  disabled?: boolean;
  id?: string;
  onChange: (value: Value) => void;
  options: OptionsOrGroups<Value>;
  placeholder?: string;
  size?: 'xsmall' | 'small' | 'medium';
  value: Readonly<Value> | undefined;
  weight?: 'none' | 'subtle';
} & ValidSelectProps;

const SelectInputInner = <Value extends string | number>(
  {
    disabled = false,
    onChange,
    options: optionsOrGroups,
    placeholder,
    size = 'medium',
    value,
    weight = 'subtle',
    ...props
  }: SelectInputProps<Value>,
  ref: ForwardedRef<HTMLSelectElement> | undefined
) => {
  const { palette, spacing } = useTheme();
  const { invalid, ...a11yProps } = useFieldContext();
  const styles = useInputStyles({ size, shape: 'square', weight });

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let stringValue = event.target.value;
    let parsedValue = Number(stringValue);
    let resolvedValue = (Number.isNaN(parsedValue)
      ? stringValue
      : parsedValue) as Value;

    onChange(resolvedValue);
  };

  const mapOptions = useCallback(
    (opt: Option<Value>) => (
      <option key={opt.value} value={opt.value} disabled={opt.disabled}>
        {opt.label}
      </option>
    ),
    []
  );

  // "small" is the lowest value available on icons
  const iconSize = size === 'xsmall' ? 'small' : size;

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
      }}
    >
      <select
        ref={ref}
        aria-invalid={invalid}
        disabled={disabled}
        value={typeof value === 'undefined' ? '' : value.toString()}
        onChange={handleChange}
        css={{
          ...styles,
          color: value ? palette.text.base : palette.text.muted,
          lineHeight: undefined,
          overflow: 'hidden', // fix for safari to prevent unwanted scrolling of parent container to occur
          textOverflow: 'ellipsis',
          paddingRight: spacing[size] * 3, // TODO: it would be way slicker if we could use {input padding right} + {icon size}
        }}
        {...a11yProps}
        {...props}
      >
        {!value || placeholder ? (
          <option value="" disabled={true}>
            {placeholder}
          </option>
        ) : null}

        {optionsOrGroups.map((optionOrGroup) => {
          if ('options' in optionOrGroup) {
            return (
              <optgroup key={optionOrGroup.label} label={optionOrGroup.label}>
                {optionOrGroup.options.map(mapOptions)}
              </optgroup>
            );
          }

          return mapOptions(optionOrGroup);
        })}
      </select>
      <span
        css={{
          pointerEvents: 'none',
          position: 'absolute',
          display: 'flex',
          right: spacing[size],
        }}
      >
        <ChevronDownIcon color="dim" size={iconSize} />
      </span>
    </div>
  );
};

export const SelectInput = forwardRef(SelectInputInner) as <
  Value extends string | number
>(
  props: SelectInputProps<Value> & {
    ref?: ForwardedRef<HTMLSelectElement>;
  }
) => ReturnType<typeof SelectInputInner>;
