/** @jsx jsx */

import { ComponentType, HTMLAttributes } from 'react';

import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { IconProps } from '@reckon-web/icon';
import { LoadingDots } from '@reckon-web/loading';
import { usePack, useTheme } from '@reckon-web/theme';
import { forwardRefWithAs } from '@reckon-web/utils';

import {
  ButtonStylesProps,
  Size,
  Tone,
  Weight,
  getButtonStyles,
} from './styles';
import { usePreventableClickHandler } from './utils';

// TODO: add a `pressed` prop

type BaseButtonProps = {
  /** The label of the button. */
  label: string;
  /** An element rendered before the button content. */
  iconBefore?: ComponentType<IconProps>;
  /** An element rendered after the button content. */
  iconAfter?: ComponentType<IconProps>;
  /** The size of the button. */
  size?: Size;
  /** When true, the button will be disabled. */
  disabled?: boolean;
  /** When true, the button will fill the available width of its container. */
  block?: boolean;
  /** When true, the button will display a loading spinner. */
  loading?: boolean;
  /** Provide an alternate type if the button is within a form. */
  type?: 'submit' | 'button' | 'reset';
};

export type ConditionalButtonProps =
  | {
      /** The weight of the button. */
      weight?: 'bold' | 'subtle';
      /** The tone that is conveyed by the button. */
      tone?: 'active' | 'critical';
    }
  | {
      /** The weight of the button. */
      weight: 'none';
      /** The tone that is conveyed by the button. */
      tone?: 'active' | 'passive';
    };

export type ButtonProps = BaseButtonProps & ConditionalButtonProps;

export const Button = forwardRefWithAs<'button', ButtonProps>(
  (
    {
      as: Tag = 'button',
      weight = 'bold' as Weight,
      tone = 'active' as Tone,
      label,
      disabled = false,
      block = false,
      iconAfter: IconAfter,
      iconBefore: IconBefore,
      loading = false,
      size = 'medium',
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const sizePacks = usePack('sizes');
    const sizePack = sizePacks[size];

    const isDisabled = disabled || loading;
    const isLoading = loading && !disabled;

    if (Tag === 'button') {
      props.type = props.type || 'button';
    }

    // styles
    const state: ButtonStylesProps = {
      block,
      borderRadius: sizePack.borderRadius,
      fontWeight: theme.typography.fontWeight.semibold,
      size: sizePack,
      tone,
      weight,
    };
    const buttonStyles = getButtonStyles(state, theme);

    // handle "disabled" behaviour w/o disabling buttons
    const handleClick = usePreventableClickHandler(props, isDisabled);

    return (
      <Tag
        css={buttonStyles}
        aria-disabled={isDisabled}
        ref={ref}
        {...props}
        // must be after prop spread
        onClick={handleClick}
      >
        <Flex alignItems="center" gap="small">
          {IconBefore && (
            <HiddenWhenLoading isLoading={isLoading}>
              <IconBefore size="small" />
            </HiddenWhenLoading>
          )}
          <HiddenWhenLoading isLoading={isLoading}>{label}</HiddenWhenLoading>
          {isLoading && (
            <Center>
              <LoadingDots
                label="button loading indicator"
                size={size}
                color="inherit"
              />
            </Center>
          )}
          {IconAfter && (
            <HiddenWhenLoading isLoading={isLoading}>
              <IconAfter size="small" />
            </HiddenWhenLoading>
          )}
        </Flex>
      </Tag>
    );
  }
);

Button.displayName = 'Button';

// Styled components
// ------------------------------

/**
 * Hide with opacity so elements maintain their dimensions, and the button
 * doesn't change shape.
 */
const HiddenWhenLoading = ({
  isLoading,
  ...props
}: HTMLAttributes<HTMLSpanElement> & { isLoading: boolean }) => (
  <span css={isLoading ? { opacity: 0 } : null} {...props} />
);

/** Position the loading indicator */
const Center = (props: HTMLAttributes<HTMLSpanElement>) => (
  <span
    css={{
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
    }}
    {...props}
  />
);
