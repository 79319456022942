/** @jsx jsx */

import {
  Children,
  Fragment,
  ReactElement,
  cloneElement,
  forwardRef,
  useEffect,
} from 'react';
import { useTooltip } from '@reach/tooltip';

import { jsx } from '@reckon-web/core';
import { usePopover } from '@reckon-web/popover';
import { Portal } from '@reckon-web/portal';
import { useTheme } from '@reckon-web/theme';
import { useForkedRef } from '@reckon-web/utils';

import { arrowStyles } from './utils';

export type TooltipProps = {
  /** The target element. */
  children: ReactElement;
  /** The content of the tooltip. */
  content: string | ReactElement;
  /** Where, in relation to the target, to place the tooltip. */
  placement?: 'top' | 'right' | 'bottom' | 'left';
  /** Which (CSS) positioning strategy to use. */
  positionStrategy?: 'absolute' | 'fixed';
};

export const Tooltip = ({
  children,
  content,
  placement = 'top',
  positionStrategy = 'absolute',
}: TooltipProps) => {
  // PopperJS stuff
  const { trigger: popperTrigger, dialog, arrow, setOpen } = usePopover({
    placement,
    strategy: positionStrategy,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  // Reach UI stuff
  const child = Children.only(children) as any;
  const childRef = useForkedRef(child.ref, popperTrigger.ref);
  const [reachTrigger, reachTooltip] = useTooltip({
    onMouseEnter: child.props.onMouseEnter,
    onMouseMove: child.props.onMouseMove,
    onMouseLeave: child.props.onMouseLeave,
    onMouseDown: child.props.onMouseDown,
    onFocus: child.props.onFocus,
    onBlur: child.props.onBlur,
    onKeyDown: child.props.onKeyDown,
    ref: childRef,
    // @ts-ignore
    disabled: child.props.disabled,
  });

  // Sync Popper's open state with Reach's, so we get the most up-to-date position
  useEffect(() => {
    setOpen(reachTooltip.isVisible);
  }, [setOpen, reachTooltip.isVisible]);

  const combinedTriggerProps = { ...reachTrigger, tabIndex: 0 }; // support non-interactive elements

  return (
    <Fragment>
      {cloneElement(children, combinedTriggerProps)}

      {reachTooltip.isVisible ? (
        <TooltipElement
          {...dialog.props}
          ref={dialog.ref}
          id={reachTrigger['aria-describedby']}
          arrow={arrow}
        >
          {content}
        </TooltipElement>
      ) : null}
    </Fragment>
  );
};

// Styled Component
// ------------------------------

type ElementProps = {
  /** The content of the tooltip. */
  children: string | ReactElement;
  /** ID used to describe the invoking element. */
  id?: string;
  arrow: any;
};

export const TooltipElement = forwardRef<HTMLDivElement, ElementProps>(
  ({ arrow, children, ...props }, consumerRef) => {
    const {
      elevation,
      palette,
      radii,
      shadow,
      spacing,
      typography,
    } = useTheme();

    const lineheight = typography.leading.base;

    return (
      <Portal>
        <div
          role="tooltip"
          ref={consumerRef}
          css={{
            background: palette.tooltip.background,
            borderRadius: radii.xsmall,
            boxShadow: shadow.small,
            color: palette.tooltip.text,
            fontSize: typography.fontSize.small,
            lineHeight: lineheight,
            maxWidth: 260, // less than desirable magic number, but not sure if this needs to be in theme...
            pointerEvents: 'none',
            padding: spacing.small,
            zIndex: elevation.toast,
            ...arrowStyles(palette, shadow),
          }}
          {...props}
        >
          <span
            css={{
              display: 'block',

              // offset lineheight
              '::before': {
                content: '" "',
                display: 'block',
                height: 0,
                marginTop: `calc((1 - ${lineheight}) * 0.5em)`,
              },
              '::after': {
                content: '" "',
                display: 'block',
                height: 0,
                marginBottom: `calc((1 - ${lineheight}) * 0.5em)`,
              },
            }}
          >
            {children}
          </span>
          <div
            data-popper-arrow
            ref={arrow.ref}
            className="tooltipArrow"
            {...arrow.props}
          />
        </div>
      </Portal>
    );
  }
);
