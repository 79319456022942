/** @jsx jsx */

import { jsx } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';

import { Base } from './Base';
import { SizeType } from './types';
import { getKeyFromText, useInitials } from './utils';

export type EntityAvatarProps = {
  /** Does not support css. */
  css?: never;
  /** @deprecated use "inactive" prop. */
  disabled?: boolean;
  /** Signal to the user that this avatar is in some way not available. */
  inactive?: boolean;
  /** Signal to the user that this avatar is less significant e.g "guest organisation"  */
  guest?: boolean;
  /** The name of the entity, note that only the first letter will be rendered. */
  name: string;
  /** The size of the entity. */
  size?: SizeType;
  /** Define the type of entity this avatar represents. */
  type: 'Organisation' | 'Person';
};

const charLength = {
  Organisation: 1,
  Person: 2,
} as const;

export const EntityAvatar = ({
  name,
  type,
  size = 'medium',
  disabled,
  inactive,
  ...props
}: EntityAvatarProps) => {
  const { palette } = useTheme();
  const color = getKeyFromText(palette.decorative, name);
  const initials = useInitials({ name, max: charLength[type], size });

  /** Support the deprecated 'disabled' prop */
  const backwardsCompatibleInactive = inactive || disabled;

  return (
    <Base
      color={color}
      shape="square"
      size={size}
      {...props}
      inactive={backwardsCompatibleInactive}
    >
      {initials}
    </Base>
  );
};
