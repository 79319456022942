/** @jsx jsx */

import { ComponentType } from 'react';

import { jsx } from '@reckon-web/core';
import { IconProps } from '@reckon-web/icon';
import { useTheme } from '@reckon-web/theme';

import { Base } from './Base';
import { SizeType } from './types';
import { getKeyFromText } from './utils';

export type InstanceProps = {
  /** Does not support css. */
  css?: never;
  /** The color of the instance. */
  color?: string;
  /** @deprecated use "inactive" prop. */
  disabled?: boolean;
  /** Signal to the user that this avatar is in some way not available. */
  inactive?: boolean;
  /** Signal to the user that this avatar is less significant   */
  guest?: boolean;
  /** The icon to place in the instance */
  icon?: ComponentType<IconProps>;
  /** The name of the instance, note that only the first letter will be rendered. */
  name?: string;
  /** The size of the instance. */
  size?: SizeType;
};

const iconSizeMap = {
  xsmall: 'small',
  small: 'medium',
  medium: 'medium',
  large: 'large',
  xlarge: 'large',
} as const;

export const Instance = ({
  name,
  icon: Icon,
  color: specificColor,
  size = 'medium',
  disabled,
  inactive,
  ...props
}: InstanceProps) => {
  const { palette } = useTheme();
  const color = specificColor || getKeyFromText(palette.decorative, name);

  /** Support the deprecated 'disabled' prop */
  const backwardsCompatibleInactive = inactive || disabled;

  return (
    <Base
      color={color}
      shape="square"
      size={size}
      {...props}
      inactive={backwardsCompatibleInactive}
    >
      {Icon ? (
        <Icon size={iconSizeMap[size]} />
      ) : (
        name?.slice(0, 1).toUpperCase()
      )}
    </Base>
  );
};
