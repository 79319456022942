/** @jsx jsx */

import { ElementType, ReactNode, createContext, useContext } from 'react';

import { jsx } from '@reckon-web/core';
import { usePack } from '@reckon-web/theme';
import { forwardRefWithAs } from '@reckon-web/utils';

import { InputShapeType, InputSizeType, InputWeightType } from './types';

/**
 * What is this thing?
 * ------------------------------
 * We expose primitive components for adorning inputs with icons and buttons.
 * There's some awkard requirements surrounding size and shape that's best to
 * consolidate in one place.
 */

const AdornmentContext = createContext<{
  shape: InputShapeType;
  size: InputSizeType;
  weight: InputWeightType;
}>({
  shape: 'square',
  size: 'medium',
  weight: 'subtle',
});
const useAdornmentContext = () => useContext(AdornmentContext);

// Adornment Wrapper
// ------------------------------

export type AdornmentWrapperProps = {
  children: ReactNode;
  shape?: InputShapeType;
  size?: InputSizeType;
  weight?: InputWeightType;
};

export const AdornmentWrapper = ({
  children,
  shape = 'square',
  size = 'medium',
  weight = 'subtle',
  ...props
}: AdornmentWrapperProps) => {
  return (
    <AdornmentContext.Provider value={{ shape, size, weight }}>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          position: 'relative',
        }}
        {...props}
      >
        {children}
      </div>
    </AdornmentContext.Provider>
  );
};

// Adornment Element
// ------------------------------

const alignmentPaddingMap = {
  left: 'marginLeft',
  right: 'marginRight',
};

type AdornmentProps = {
  align: 'left' | 'right';
  as?: ElementType;
};
export const Adornment = forwardRefWithAs<'div', AdornmentProps>(
  ({ align, as: Tag = 'div', ...props }, ref) => {
    const { shape, size } = useAdornmentContext();
    const sizes = usePack('sizes');
    const { boxSize, paddingX } = sizes[size];

    // optical alignment shifts towards the middle of the container with the large
    // border radius on "round" inputs. use padding rather than margin to optimise
    // the hit-area of interactive elements
    const offsetStyles =
      shape === 'round'
        ? {
            [alignmentPaddingMap[align]]: paddingX / 4,
          }
        : null;

    return (
      <Tag
        ref={ref}
        css={{
          [align]: 0,
          alignItems: 'center',
          display: 'flex',
          height: boxSize,
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          width: boxSize,
          ...offsetStyles,
        }}
        {...props}
      />
    );
  }
);
