/** @jsx jsx */

import {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
  useCallback,
  useRef,
} from 'react';

import { jsx } from '@reckon-web/core';
import { useForkedRef } from '@reckon-web/utils';

import { UseTextLinkProps, useTextLink } from './useTextLink';

export type TextLinkButtonProps = UseTextLinkProps & {
  /** If the button is disabled */
  disabled?: boolean;
} & HTMLAttributes<HTMLSpanElement>;

// NOTE: Rather than rendering a native button element, we render a span element
// with an ARIA role of button to avoid issues with text behaviour. Resolves:
// - alignment
// - truncating
// - wrapping

/**
 * The text link button should only be used for *semantic* buttons. If you want to
 * link to another route or an external website use the `TextLink` instead.
 *
 * @see https://balance.reckon.com/package/text-link
 */
export const TextLinkButton = forwardRef<HTMLSpanElement, TextLinkButtonProps>(
  ({ color = 'link', data, id, disabled, ...consumerProps }, forwardedRef) => {
    const { testingProps, styles } = useTextLink({ color, data, id });

    const internalRef = useRef<HTMLSpanElement>(null);
    const ref = useForkedRef(internalRef, forwardedRef);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLSpanElement>) => {
        if (!disabled && (event.key === 'Enter' || event.key === ' ')) {
          event.preventDefault();
          internalRef.current?.click();
        }
      },
      [internalRef, disabled]
    );

    const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
      if (disabled || !consumerProps.onClick) return;
      consumerProps.onClick(event);
    };

    return (
      <span
        role="button"
        aria-disabled={disabled}
        ref={ref}
        css={styles}
        tabIndex={disabled ? undefined : 0}
        onKeyDown={handleKeyDown}
        {...testingProps}
        {...consumerProps}
        onClick={handleClick}
      />
    );
  }
);
