/** @jsx jsx */

import { forwardRef } from 'react';

import { LinkComponentProps, jsx, useLinkComponent } from '@reckon-web/core';

import { UseTextLinkProps, useTextLink } from './useTextLink';

export type TextLinkProps = LinkComponentProps & UseTextLinkProps;

/**
 * Text links take users to another place in the application, using the "href"
 * prop. If you are *only* providing "onClick" use the `TextLinkButton` instead.
 *
 * @see https://balance.reckon.com/package/text-link
 */
export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ color = 'link', data, id, ...consumerProps }, ref) => {
    const LinkComponent = useLinkComponent();
    const { testingProps, styles } = useTextLink({ color, data, id });

    return (
      <LinkComponent
        ref={ref}
        css={styles}
        {...testingProps}
        {...consumerProps}
      />
    );
  }
);
