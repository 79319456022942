import { usePack, useTheme } from '@reckon-web/theme';

import { InputShapeType, InputSizeType, InputWeightType } from './types';

export type InputBaseProps = {
  shape: InputShapeType;
  size: InputSizeType;
  weight: InputWeightType;
};

export function useInputStyles({ shape, size, weight }: InputBaseProps) {
  const { palette, typography } = useTheme();
  const sizes = usePack('sizes');
  const { borderRadius, boxSize, fontSizeText, paddingX } = sizes[size];
  const backgroundColor =
    weight === 'none' ? 'transparent' : palette.formInput.background;
  const borderColor =
    weight === 'none' ? 'transparent' : palette.formInput.border;
  const fontWeight =
    weight === 'none'
      ? typography.fontWeight.medium
      : typography.fontWeight.regular;

  return {
    appearance: 'none',
    backgroundColor: backgroundColor,
    border: `1px solid ${borderColor}`,
    borderRadius: shape === 'round' ? 9999 : borderRadius,
    boxSizing: 'border-box',
    color: palette.formInput.text,
    fontFamily: typography.fontFamily.body,
    fontSize: fontSizeText,
    fontWeight,
    outline: 0,
    paddingLeft: paddingX,
    paddingRight: paddingX,
    textAlign: 'inherit',
    width: '100%',

    // things that aren't textarea (input, select etc.)
    height: boxSize,
    lineHeight: `${boxSize}px`, // fix vertical alignment of text
    paddingBottom: 0,
    paddingTop: 0,

    // alternate styles for textarea
    'textarea&': {
      lineHeight: typography.leading.tight,
      minHeight: boxSize,
      height: 'auto',
      paddingBottom: '0.66em',
      paddingTop: '0.66em',
      resize: 'vertical',
    },

    ':hover': {
      backgroundColor: palette.formInput.backgroundHovered,
      borderColor: palette.formInput.borderHovered,
    },
    ':focus': {
      backgroundColor: palette.formInput.background,
      borderColor: palette.formInput.borderFocused,
      boxShadow: `0 0 0 2px ${palette.global.focusRing}`,
    },

    // disabled state
    ':disabled': {
      backgroundColor: palette.formInput.backgroundDisabled,
      borderColor: palette.formInput.borderDisabled,
      color: palette.formInput.textDisabled,
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },

    // invalid state
    // NOTE: the data attribute is specifically for the current date-picker
    // implementation where we use a button, so `aria-invalid` is not
    // allowed/supported
    '&[data-invalid=true], &[aria-invalid=true]': {
      backgroundColor: palette.formInput.backgroundInvalid,
      borderColor: palette.formInput.borderInvalid,

      ':focus': {
        borderColor: palette.formInput.borderFocused,
      },
    },

    // placeholder text
    '&::placeholder': {
      color: palette.formInput.textPlaceholder,
    },
  } as const;
}
