/** @jsx jsx */
import { ReactNode, useMemo } from 'react';

import { jsx } from '@reckon-web/core';
import { Stack } from '@reckon-web/stack';
import type { TooltipProps } from '@reckon-web/tooltip';
import { makeId, useId } from '@reckon-web/utils';

import { FieldContext } from './context';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { FieldMessage } from './FieldMessage';
import { HelpIndicator } from './HelpIndicator';

/**
 * About
 * ------------------------------
 * This form field component consolidates the elements **around** an input with
 * a single API. The a11y requirements are too specific to consider composition
 * within each exposed field type.
 */

type FieldRenderProps = {
  inputProps: {
    'aria-describedby'?: string;
    invalid: boolean;
    id: string;
  };
} & Omit<ReturnType<typeof useFieldIds>, 'inputId'>;

export type FieldProps = {
  /** Optionally describe the intent of the input where the label alone would not suffice. */
  description?: string;
  /**
   * Help text is hidden by default, and revealed in a tooltip from an icon
   * beside the label. Use help text to clarify anything that may be ambiguous
   * about the input. In most cases a description is preferred.
   */
  helpText?: TooltipProps['content'];
  /** Used for aria attributes and various element ID suffixes. Defaults to a generated ID. */
  id?: string;
  /** The label above the input. */
  label: string;
  /** When false, the label will only be visible to screen-readers. */
  labelVisible?: boolean;
  /** Explain how the user's input doesn't meet the requirements of the field. */
  invalidMessage?: string;
  /**
   * Reserve the vertical space that will be taken by an invalid message. You
   * would use this to stop subsequent elements from moving when an invalid
   * message is displayed.
   */
  reserveMessageSpace?: boolean;
};

/** @private */
type InternalFieldProps = FieldProps & {
  /** Called with arguments derived from internal state. */
  children: ReactNode | ((fieldRenderProps: FieldRenderProps) => ReactNode);
};

// Component
// ------------------------------

export const Field = (props: InternalFieldProps) => {
  const {
    children,
    description,
    helpText,
    id: _id,
    invalidMessage,
    label,
    labelVisible,
    reserveMessageSpace,
  } = props;

  // generate IDs
  const { descriptionId, inputId, labelId, messageId } = useFieldIds(_id);
  const inputDescribedBy = invalidMessage
    ? messageId
    : description
    ? descriptionId
    : undefined;

  const labelElement = (
    <FieldLabel htmlFor={inputId} id={labelId} visible={labelVisible}>
      {label}
    </FieldLabel>
  );

  const context = useMemo(
    () => ({
      'aria-describedby': inputDescribedBy,
      invalid: Boolean(invalidMessage),
      id: inputId,
    }),
    [inputDescribedBy, invalidMessage, inputId]
  );

  const childrenElement =
    typeof children === 'function'
      ? children({
          inputProps: context,
          descriptionId,
          labelId,
          messageId,
        })
      : children;

  return (
    <FieldContext.Provider value={context}>
      <Stack gap="small">
        {helpText ? (
          // NOTE: this div will NOT collapse (into the stack grid) when the label
          // is visually hidden. This is a pretty low-impact bug that's very
          // tricky to resolve. We mitigate the issue here by only rendering the
          // div if a help indicator is required.
          <div css={{ alignItems: 'center', display: 'flex' }}>
            {labelElement}
            <HelpIndicator content={helpText} />
          </div>
        ) : (
          labelElement
        )}

        {description && (
          <FieldDescription id={descriptionId}>{description}</FieldDescription>
        )}

        {childrenElement}

        {(invalidMessage || reserveMessageSpace) && (
          <FieldMessage id={messageId}>
            {invalidMessage || <div>&nbsp;</div>}
          </FieldMessage>
        )}
      </Stack>
    </FieldContext.Provider>
  );
};

// Utils
// ------------------------------

export function useFieldIds(consumerID?: string) {
  const id = useId(consumerID);
  const descriptionId = makeId('field-description', id);
  const inputId = makeId('field-input', id);
  const labelId = makeId('field-label', id);
  const messageId = makeId('field-message', id);

  return { descriptionId, inputId, labelId, messageId };
}
