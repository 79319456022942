/** @jsx jsx */

import { InputHTMLAttributes, forwardRef } from 'react';

import { jsx } from '@reckon-web/core';
import { useFieldContext } from '@reckon-web/field';

import { useInputStyles } from './utils';
import { InputSizeType, InputWeightType } from './types';

const validTypes = {
  email: 'email',
  number: 'number',
  password: 'password',
  search: 'search',
  tel: 'tel',
  text: 'text',
  url: 'url',
};

type InputProps = InputHTMLAttributes<HTMLInputElement>;
export type TextInputProps = {
  // FIXME: temp solution for invalid input w/o displaying an invalid message. we might need a "hideMessage" prop on `Field` to support assistive tech
  invalid?: boolean;
  onChange: NonNullable<InputProps['onChange']>;
  size?: InputSizeType;
  type?: keyof typeof validTypes;
  value: NonNullable<InputProps['value']>;
  weight?: InputWeightType;
} & Omit<InputProps, 'onChange' | 'type' | 'size' | 'value'>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      invalid: invalidProp,
      size = 'medium',
      type = 'text',
      weight = 'subtle',
      ...props
    },
    ref
  ) => {
    const styles = useInputStyles({ size, shape: 'square', weight });
    const { invalid, ...a11yProps } = useFieldContext();
    const isInvalid = invalidProp ?? invalid;

    return (
      <input
        aria-invalid={isInvalid}
        ref={ref}
        type={type}
        css={styles}
        {...a11yProps}
        {...props}
      />
    );
  }
);
