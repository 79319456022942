import { simpleHash } from '@reckon-web/utils';

import { useAvatarStackContext } from './context';
import { SizeType } from './types';

const TEXT_FALLBACK = '?';

export function getKeyFromText(keys: string[], text: string = TEXT_FALLBACK) {
  const hash = simpleHash(text);
  const key = keys[hash % keys.length];

  return key;
}

export function getInitials(text?: string, fallback = TEXT_FALLBACK) {
  if (!text || typeof text !== 'string') return fallback;
  if (text.length <= 2) return text.toUpperCase(); // assume the consumer has provided custom initials

  return text
    .replace(/\s+/, ' ') // Repeated spaces results in empty strings
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
    .join('');
}

type HookOptions = {
  /** The max character length the initials are allowed to be. */
  max: 1 | 2;
  /** The name of the person represented by the avatar. */
  name: string;
  /** The size of the avatar. */
  size?: SizeType;
};

export const useInitials = ({ max, name, size }: HookOptions) => {
  const stackContext = useAvatarStackContext();
  const resolvedSize = stackContext?.size || size;
  const allowedCharLength = resolvedSize === 'xsmall' ? 1 : max; // ensure a single character only is rendered within an "xsmall" avatar

  return getInitials(name).slice(0, allowedCharLength);
};
