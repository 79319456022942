/** @jsx jsx */
import { CSSProperties } from 'react';

import { ResponsiveProp } from '@reckon-web/core';
import { PaletteType, ReckonTheme, useTheme } from '@reckon-web/theme';
import { useMediaQuery } from '@reckon-web/media-query';

// TODO perf review

type SpacingType = ResponsiveProp<keyof ReckonTheme['spacing']>;

export type MarginProps = {
  /**
   * The `margin` shorthand property sets the margin area on all four sides of
   * an element at once.
   */
  margin?: SpacingType;
  /**
   * The `marginTop` property sets the margin area on the top side of an
   * element.
   */
  marginTop?: SpacingType;
  /**
   * The `marginRight` property sets the margin area on the right side of an
   * element.
   */
  marginRight?: SpacingType;
  /**
   * The `marginBottom` property sets the margin area on the bottom side of an
   * element.
   */
  marginBottom?: SpacingType;
  /**
   * The `marginLeft` property sets the margin area on the left side of an
   * element.
   */
  marginLeft?: SpacingType;
  /**
   * The `marginY` shorthand property sets the margin area on the top and
   * bottom of the element.
   */
  marginY?: SpacingType;
  /**
   * The `marginX` shorthand property sets the margin area on the left and
   * right of the element.
   */
  marginX?: SpacingType;
};

export type PaddingProps = {
  /**
   * The `padding` shorthand property sets the padding area on all four sides
   * of an element at once.
   */
  padding?: SpacingType;
  /**
   * The `paddingTop` property sets the height of the padding area on the top
   * of an element.
   */
  paddingTop?: SpacingType;
  /**
   * The `paddingRight` property sets the width of the padding area on the
   * right of an element.
   */
  paddingRight?: SpacingType;
  /**
   * The `paddingBottom` property sets the height of the padding area on the
   * bottom of an element.
   */
  paddingBottom?: SpacingType;
  /**
   * The `paddingLeft` property sets the width of the padding area on the left
   * of an element.
   */
  paddingLeft?: SpacingType;
  /**
   * The `paddingY` shorthand property sets the padding area on the top and
   * bottom of the element.
   */
  paddingY?: SpacingType;
  /**
   * The `paddingX` shorthand property sets the padding area on the left and
   * right of the element.
   */
  paddingX?: SpacingType;
};

export type ChildFlexProps = {
  /**
   * The `alignSelf` property overrides a flex item's `align-items` value. In
   * Flexbox, it aligns the item on the cross axis.
   */
  alignSelf?: ResponsiveProp<CSSProperties['alignSelf']>;
  /**
   * The `flex` shorthand property sets how a flex item will grow or shrink to
   * fit the space available in its flex container.
   */
  flex?: ResponsiveProp<CSSProperties['flex']>;
  /** The `flexBasis` property sets the initial main size of a flex item. */
  flexBasis?: ResponsiveProp<CSSProperties['flexBasis']>;
  /** The `flexGrow` property sets the flex grow factor of a flex item main size. */
  flexGrow?: ResponsiveProp<CSSProperties['flexGrow']>;
  /**
   * The `flexShrink` property sets the flex shrink factor of a flex item. If
   * the size of all flex items is larger than the flex container, items shrink
   * to fit according to `flex-shrink`.
   */
  flexShrink?: ResponsiveProp<CSSProperties['flexShrink']>;
};

type PositionProps = {
  /**
   * The `position` property sets how an element is positioned in a document.
   * The `top`, `right`, `bottom`, and `left` properties determine the final
   * location of positioned elements.
   */
  position?: ResponsiveProp<CSSProperties['position']>;
  /**
   * The `top` property participates in specifying the vertical position of a
   * positioned element. It has no effect on non-positioned elements.
   */
  top?: ResponsiveProp<CSSProperties['top']>;
  /**
   * The `right` property participates in specifying the horizontal position of
   * a positioned element. It has no effect on non-positioned elements.
   */
  right?: ResponsiveProp<CSSProperties['right']>;
  /**
   * The `bottom` property participates in setting the vertical position of a
   * positioned element. It has no effect on non-positioned elements.
   */
  bottom?: ResponsiveProp<CSSProperties['bottom']>;
  /**
   * The `left` property participates in specifying the horizontal position of a
   * positioned element. It has no effect on non-positioned elements.
   */
  left?: ResponsiveProp<CSSProperties['left']>;
  /**
   * The `zIndex` property sets the "z-order" of a positioned element and its
   * descendants or flex items. Overlapping elements with a larger z-index cover
   * those with a smaller one.
   */
  zIndex?: ResponsiveProp<keyof ReckonTheme['elevation']>;
};

type DimensionProps = {
  /** The `height` property sets an element's height. */
  height?: ResponsiveProp<CSSProperties['height']>;
  /** The `width` property sets an element's width. */
  width?: ResponsiveProp<CSSProperties['width']>;
  /**
   * The `minHeight` property sets the minimum height of an element. It prevents
   * the used value of the height property from becoming smaller than the value
   * specified for `minHeight`.
   */
  minHeight?: ResponsiveProp<CSSProperties['minHeight']>;
  /**
   * The `minWidth` property sets the minimum width of an element. It prevents
   * the used value of the width property from becoming smaller than the value
   * specified for `minWidth`.
   */
  minWidth?: ResponsiveProp<CSSProperties['minWidth']>;
  /**
   * The `maxHeight` CSS property sets the maximum height of an element. It
   * prevents the used value of the height property from becoming larger than
   * the value specified for `maxHeight`.
   */
  maxHeight?: ResponsiveProp<CSSProperties['maxHeight']>;
  /**
   * The `maxWidth` CSS property sets the maximum width of an element. It
   * prevents the used value of the width property from becoming larger than the
   * value specified by `maxWidth`.
   */
  maxWidth?: ResponsiveProp<CSSProperties['maxWidth']>;
};

type BaseBoxProps = {
  /** The `background` property sets the background color of an element. */
  background?: ResponsiveProp<keyof PaletteType['background']>;
  /** The `border` property sets the color of an element's border. */
  border?: ResponsiveProp<keyof PaletteType['border']>;
  /**
   * The `borderRadius` property rounds the corners of an element's outer
   * border edge.
   */
  borderRadius?: ResponsiveProp<keyof ReckonTheme['radii']>;
  /**
   * The `borderWidth` shorthand property sets the width of an element's
   * border.
   */
  borderWidth?: ResponsiveProp<keyof ReckonTheme['borderWidth']>;
  /** The `boxShadow` property adds shadow effects around an element's frame. */
  boxShadow?: ResponsiveProp<keyof ReckonTheme['shadow']>;
  /**
   * The `cursor` property sets the type of mouse cursor, if any, to show when
   * the mouse pointer is over an element.
   */
  cursor?: ResponsiveProp<CSSProperties['cursor']>;
  /**
   * The `overflow` shorthand property sets the desired behavior for an
   * element's overflow — i.e. when an element's content is too big to fit in
   * its block formatting context — in both directions.
   */
  overflow?: ResponsiveProp<CSSProperties['overflow']>;
  /**
   * The `overflowY` property sets what shows when content overflows a
   * block-level element's left and right edges.
   */
  overflowX?: ResponsiveProp<CSSProperties['overflowX']>;
  /**
   * The `overflowY` property sets what shows when content overflows a
   * block-level element's top and bottom edges.
   */
  overflowY?: ResponsiveProp<CSSProperties['overflowY']>;
  /** The `userSelect` property controls whether the user can select text. */
  userSelect?: ResponsiveProp<CSSProperties['userSelect']>;
};

export type BoxStyleProps = DimensionProps &
  PositionProps &
  ChildFlexProps &
  MarginProps &
  PaddingProps &
  BaseBoxProps;

export const useBoxStyles = ({
  alignSelf,
  background,
  border,
  borderRadius,
  borderWidth = 'standard',
  bottom,
  boxShadow,
  cursor,
  flex,
  flexBasis,
  flexGrow,
  flexShrink,
  height,
  left,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  overflowX,
  overflowY,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position,
  right,
  top,
  userSelect,
  width,
  zIndex,
}: BoxStyleProps) => {
  const theme = useTheme();
  const { mq, mapResponsiveProp } = useMediaQuery();

  const borderStyles = border
    ? {
        borderStyle: 'solid',
        borderColor: mapResponsiveProp(border, theme.palette.border),
        borderWidth: mapResponsiveProp(borderWidth, theme.borderWidth),
      }
    : null;
  const marginStyles = useMargin(
    {
      margin,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      marginY,
      marginX,
    },
    theme
  );
  const paddingStyles = usePadding(
    {
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingY,
      paddingX,
    },
    theme
  );

  const resolvedBackground = background
    ? mapResponsiveProp(background, theme.palette.background)
    : null;
  const resolvedBorderRadius = borderRadius
    ? mapResponsiveProp(borderRadius, theme.radii)
    : null;
  const resolvedBoxShadow = boxShadow
    ? mapResponsiveProp(boxShadow, theme.shadow)
    : null;
  const resolvedZIndex = zIndex
    ? mapResponsiveProp(zIndex, theme.elevation)
    : null;

  return mq({
    ...borderStyles,
    ...marginStyles,
    ...paddingStyles,
    backgroundColor: resolvedBackground,
    borderRadius: resolvedBorderRadius,
    boxShadow: resolvedBoxShadow,
    zIndex: resolvedZIndex,

    // misc.
    cursor,
    userSelect,

    // allow padding and height/width props to play nice
    boxSizing: 'border-box',

    // overflow
    overflow,
    overflowX,
    overflowY,

    // flex
    alignSelf,
    flex,
    flexBasis,
    flexGrow,
    flexShrink,

    // dimension
    height,
    width,
    minHeight,
    minWidth,
    maxHeight,
    maxWidth,

    // position
    position,
    top,
    right,
    bottom,
    left,
  });
};

// Utils
// ------------------------------

function usePadding(
  {
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingY,
    paddingX,
  }: PaddingProps,
  { spacing }: ReckonTheme
) {
  const { mapResponsiveProp } = useMediaQuery();

  let pb = paddingBottom || paddingY || padding;
  let pt = paddingTop || paddingY || padding;
  let pl = paddingLeft || paddingX || padding;
  let pr = paddingRight || paddingX || padding;

  return {
    paddingBottom: pb && mapResponsiveProp(pb, spacing),
    paddingTop: pt && mapResponsiveProp(pt, spacing),
    paddingLeft: pl && mapResponsiveProp(pl, spacing),
    paddingRight: pr && mapResponsiveProp(pr, spacing),
  };
}
function useMargin(
  {
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginY,
    marginX,
  }: MarginProps,
  { spacing }: ReckonTheme
) {
  const { mapResponsiveProp } = useMediaQuery();

  let mb = marginBottom || marginY || margin;
  let mt = marginTop || marginY || margin;
  let ml = marginLeft || marginX || margin;
  let mr = marginRight || marginX || margin;

  return {
    marginBottom: mb && mapResponsiveProp(mb, spacing),
    marginTop: mt && mapResponsiveProp(mt, spacing),
    marginLeft: ml && mapResponsiveProp(ml, spacing),
    marginRight: mr && mapResponsiveProp(mr, spacing),
  };
}
