/** @jsx jsx */

import { ComponentType } from 'react';

import { jsx } from '@reckon-web/core';
import { usePack, useTheme } from '@reckon-web/theme';
import { forwardRefWithAs } from '@reckon-web/utils';
import { IconProps } from '@reckon-web/icon';

import {
  ButtonStylesProps,
  Size,
  Tone,
  Weight,
  getButtonStyles,
} from './styles';
import { usePreventableClickHandler } from './utils';

type IconButtonProps = {
  /** The label of the button, passed to `aria-label`. */
  label: string;
  /** The weight of the button. */
  weight?: Weight;
  /** When true, the button will be disabled. */
  disabled?: boolean;
  /** The icon to place in the button */
  icon: ComponentType<IconProps>;
  /** The tone that is conveyed by the button. */
  tone?: Tone;
  /** Provide an alternate type if the button is within a form. */
  type?: HTMLButtonElement['type'];
  /** The size of the button. */
  size?: Size;
};

export const IconButton = forwardRefWithAs<'button', IconButtonProps>(
  (
    {
      as: Tag = 'button',
      disabled = false,
      icon: Icon,
      label,
      size = 'medium',
      tone = 'active',
      weight = 'bold',
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const sizePacks = usePack('sizes');
    const sizePack = sizePacks[size];

    if (Tag === 'button') {
      props.type = props.type || 'button';
    }

    // styles
    const state: ButtonStylesProps = {
      borderRadius: '50%',
      disabled,
      size: sizePack,
      tone,
      weight,
    };
    const buttonStyles = {
      ...getButtonStyles(state, theme),
      width: sizePack.boxSize,
      height: sizePack.boxSize,
      padding: 0, // remove native <button/> padding so icons are centered correctly
    };

    // handle "disabled" behaviour w/o disabling buttons
    const handleClick = usePreventableClickHandler(props, disabled);

    return (
      <Tag
        css={buttonStyles}
        aria-disabled={disabled}
        aria-label={label}
        ref={ref}
        {...props}
        // must be after prop spread
        onClick={handleClick}
      >
        <Icon size="small" />
      </Tag>
    );
  }
);
