import {
  CSSObject,
  DataAttributeMap,
  buildDataAttributes,
} from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';
import { hexToRgb } from '@reckon-web/utils';

export type UseTextLinkProps = {
  color?: 'base' | 'link';
  data?: DataAttributeMap;
  id?: string;
  /** does not support css */
  css?: never;
  /** does not support style */
  style?: never;
  /** hover state from parent */
  hover?: boolean;
};

export function useTextLink({
  color = 'link',
  data,
  id,
  hover,
}: UseTextLinkProps) {
  const { palette, typography } = useTheme();
  const resolvedColor = palette.text[color];

  const testingProps = {
    id,
    ...(data ? buildDataAttributes(data) : undefined),
  };

  const styles: CSSObject = {
    color: resolvedColor,
    cursor: 'pointer',
    fontWeight: typography.fontWeight.medium,
    textDecoration: 'underline',
    // FIXME: avoid colour mutation outside the theme
    textDecorationColor: hover ? resolvedColor : hexToRgb(resolvedColor, 0.32),

    // cover all cases by desaturating and lowering opacity
    '&[aria-disabled=true]': {
      cursor: 'default',
      filter: 'grayscale(100%)',
      mixBlendMode: 'luminosity', // pick up the background hue to avoid harsh neutrals
      opacity: 0.6,
    },

    '&:not([aria-disabled=true])': {
      ':hover, :focus': {
        textDecorationColor: resolvedColor,
      },
    },

    '&:not([aria-disabled=true]).focus-visible': {
      outline: `2px solid ${palette.global.focusRing}`,
      outlineOffset: 2,
    },
  };

  return { testingProps, styles };
}
