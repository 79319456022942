import React, { ReactNode, createContext, useContext } from 'react';

import { ReckonPacks, ReckonTheme, packs, themeLight } from './theme';
import {
  PaletteSignature,
  PaletteType,
  PartialPaletteSignature,
  defaultPaletteResolver,
} from './defaultPaletteResolver';
import { DeepPartial, mergeDeep } from './utils';
// theme context

export const ThemeContext = createContext<{
  theme: ReckonTheme;
  palette: PaletteType;
  paletteResolver: PaletteSignature;
  packs: ReckonPacks;
}>({
  theme: themeLight,
  palette: defaultPaletteResolver(themeLight.colors),
  paletteResolver: defaultPaletteResolver,
  packs: packs(themeLight),
});

export type ThemeProviderProps = {
  theme?: DeepPartial<ReckonTheme>;
  paletteResolver?: PartialPaletteSignature;
  children: ReactNode;
};

export const ThemeProvider = ({
  theme = {},
  paletteResolver = () => ({}),
  children,
}: ThemeProviderProps) => {
  const { paletteResolver: outerResolver } = useContext(ThemeContext);
  const ancestralTheme = useTheme();
  const mergedTheme = mergeDeep(ancestralTheme, theme);

  const combinedResolver = (colors: ReckonTheme['colors']) => {
    return mergeDeep(outerResolver(colors), paletteResolver(colors));
  };
  const resolvedPalette = combinedResolver(mergedTheme.colors);

  return (
    <ThemeContext.Provider
      value={{
        theme: mergedTheme,
        palette: resolvedPalette,
        paletteResolver: combinedResolver,
        packs: packs(mergedTheme),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const { palette, theme } = useContext(ThemeContext);
  return { ...theme, palette };
};

export type PackKeys = keyof ReckonPacks;
export const usePack = <Key extends PackKeys>(key: Key): ReckonPacks[Key] => {
  const { packs } = useContext(ThemeContext);
  return packs[key];
};
