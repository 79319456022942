import { PaletteType } from './defaultPaletteResolver';
import { DeepPartial, mergeDeep } from './utils';

// Theme
// ------------------------------

export const themeLight = {
  name: 'Reckon Web: Light',
  typography: {
    fontFamily: {
      monospace:
        '"SFMono-Medium","SF Mono","Segoe UI Mono","Roboto Mono","Ubuntu Mono", Menlo, Consolas, Courier, monospace',
      body:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    },
    fontSize: {
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem',
      xxxlarge: '1.875rem',
      xxxxlarge: '2.25rem',
      xxxxxlarge: '3rem',
      xxxxxxlarge: '4rem',
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      heavy: 800,
    },
    leading: {
      tighter: 1,
      tight: 1.2,
      base: 1.4,
      loose: 1.6,
      looser: 1.8,
    },
  },

  // base colours used to derive the palette
  colors: {
    background: '#ffffff',
    text: '#20262D',
    primary: '#007AFF',
    brand: '#F84184',
    accent: '#3C3391',

    // intent
    danger: '#E30613',
    warning: '#FFB027',
    success: '#009754',
    info: '#0091EA',

    // non-semantic colors for avatars etc.
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],

    // product-specific colors for tags etc.
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
  },

  // misc
  borderWidth: {
    standard: 1,
    large: 2,
  },
  breakpoints: {
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1200,
  },
  contentWidth: {
    xsmall: 448,
    small: 720,
    medium: 1024,
    large: 1200,
    xlarge: 1440,
  },
  elevation: {
    background: -1,
    card: 100,
    dropdown: 200,
    sticky: 300,
    modal: 400,
    toast: 500,
  },
  radii: {
    none: 0,
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 12,
    full: 9999,
  },
  spacing: {
    none: 0,
    xxsmall: 2,
    xsmall: 4,
    small: 8,
    medium: 12,
    large: 16,
    xlarge: 24,
    xxlarge: 32,
  },
  sizing: {
    xxsmall: 16,
    xsmall: 24,
    small: 32,
    base: 40,
    medium: 48,
    large: 56,
    xlarge: 72,
  },
  shadow: {
    xsmall: `0px 1px 2px rgba(0, 0, 0, 0.2)`, // Cards
    small: `0px 2px 4px rgba(0, 0, 0, 0.2)`, // Inline dialogs (popover)
    medium: `0px 2px 8px rgba(0, 0, 0, 0.2)`, // Tooltip
    large: `0px 4px 16px rgba(0, 0, 0, 0.2)`, // Modals
    xlarge: `-8px 8px 32px rgba(0, 0, 0, 0.2)`, // Toasts (notifications)
  },
};

export type ReckonTheme = typeof themeLight;
export type ReckonThemeWithPalette = ReckonTheme & { palette: PaletteType };

// Sizes
// ------------------------------

// TODO: get rid of packs

export type SizePack = {
  /** The border radius, where applicable, of the element. */
  borderRadius: number;
  /** The height of the element, and in some cases, also the width e.g. avatars */
  boxSize: number;
  /** The font-size to use for "display-like" elements such as avatars and entities. */
  fontSizeDisplay: number | string;
  /** The font-size to use for "control-like" elements such as inputs and buttons. */
  fontSizeText: number | string;
  /** The size of the gap between sibling elements _within_ this element */
  gap: number;
  /** The size of the vertical gutter around the contents of this element. When `boxSize` is employed for height this may be ignored. */
  paddingY: number;
  /** The size of the horizontal gutter around the contents of this element */
  paddingX: number;
};

const sizePacks = (theme: ReckonTheme) => ({
  xsmall: {
    fontSizeDisplay: theme.typography.fontSize.xsmall,
    fontSizeText: theme.typography.fontSize.xsmall,
    borderRadius: theme.radii.xsmall,
    boxSize: theme.sizing.xsmall, // 24
    gap: theme.spacing.xsmall,
    paddingX: theme.spacing.small,
    paddingY: theme.spacing.xxsmall,
  },
  small: {
    fontSizeDisplay: theme.typography.fontSize.xsmall,
    fontSizeText: theme.typography.fontSize.small,
    borderRadius: theme.radii.small,
    boxSize: theme.sizing.small, // 32
    gap: theme.spacing.small,
    paddingX: theme.spacing.medium,
    paddingY: theme.spacing.xsmall,
  },
  medium: {
    fontSizeDisplay: theme.typography.fontSize.large,
    fontSizeText: theme.typography.fontSize.medium,
    borderRadius: theme.radii.medium,
    boxSize: theme.sizing.base, // 40
    gap: theme.spacing.small,
    paddingX: theme.spacing.large,
    paddingY: theme.spacing.small,
  },
});

export const packs = (theme: ReckonTheme) => ({
  sizes: sizePacks(theme),
});

export type ReckonPacks = ReturnType<typeof packs>;

// deep merge helper to maintain theme types
export function makeTheme(
  source: ReckonTheme,
  partial: DeepPartial<ReckonTheme>
) {
  return mergeDeep(source, partial);
}
