/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { Heading } from '@reckon-web/heading';
import { Button } from '@reckon-web/button';
import { CloudOffIcon } from '@reckon-web/icon/icons/CloudOffIcon';
import { TextLink } from '@reckon-web/text-link';
import { Stack } from '@reckon-web/stack';
import { Box } from '@reckon-web/box';

export function GenericError() {
  return (
    <Stack
      align="center"
      gap="xlarge"
      position="relative"
      maxWidth={560}
      top={260}
      css={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <Box marginBottom="medium">
        <CloudOffIcon color="dim" />
      </Box>
      <Heading align="center" level="3">
        Something went wrong
      </Heading>
      <Text align="center" color="muted" weight="medium">
        The issue may be temporary. Please try again. If the problem persists,
        get in touch with our friendly team at{' '}
        <TextLink href="https://www.theaccessgroup.com/en-au/contact-directory">
          theaccessgroup.com
        </TextLink>
      </Text>
      <Button label="Refresh page" onClick={() => window.location.reload()} />
    </Stack>
  );
}
